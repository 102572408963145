.career-main {
   .banner {
      align-items: center;
      
      &__content {
         color: #fff;
         text-align: center;

         .title {
            margin-bottom: 24px;

            font-size: rem(56px);
            line-height: rem(68px);

            @media (max-width: 1024px) {
               font-size: rem(32px);
               line-height: rem(44px);
            }
         }

         .description {
            font-size: rem(32px);
            line-height: rem(44px);

            @media (max-width: 1024px) {
               font-size: rem(20px);
               line-height: rem(32px);
            }
            
            margin-bottom: 0px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;

            @media (min-width: 769px) {
               -webkit-line-clamp: 4;
            }

            @media (max-width: 768px) {
               -webkit-line-clamp: 6;
            }

            @media (max-height: 500px) {
               -webkit-line-clamp: 2;
            }
         }

         a.btn {
            color: #fff;
            border-color: #fff;
            margin-top: 40px;
            padding-right: 51px;
            transition: 200ms all ease-in-out;

            &:hover {
               border-color: $purple;
               background-color: $purple;
            }
         }
      }
   }
}