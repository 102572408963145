.banner {
    position: relative;
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 56px;
    overflow: hidden;

    &::before {
        content: '';
        display: block;
        position: absolute;
        inset: 0;
        background-color: #0008;
    }

    @media (max-width: 1024px) {
        padding-bottom: 28px;
        margin-bottom: 82px;
    }
    
    @media (min-width: 1025px) {
        padding-top: 120px;
    }

    &__content {
        color: #fff;
        position: relative;

        .title {
            margin-bottom: 24px;

            // @media (max-height: 767px) {
            //     margin-bottom: 0;
            // }

            h1 {
                font-size: rem(56px);
                line-height: rem(68px);
                margin-bottom: 0;

                @media (max-width: 1024px) {
                    font-size: rem(32px);
                    line-height: rem(44px);
                }
            }
        }

        .description {
            font-size: rem(32px);
            line-height: rem(44px);

            @media (max-width: 1024px) {
                font-size: rem(20px);
                line-height: rem(32px);
            }

            p {
                margin-bottom: 0px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                
                @media (min-width: 769px) {
                    -webkit-line-clamp: 4;
                }
                
                @media (max-width: 768px) {
                    -webkit-line-clamp: 6;
                }
                
                @media (max-height: 500px) {
                    -webkit-line-clamp: 2;
                }
            }
        }
    }

    &.playing {
        #bannerPlayButton {
            display: none;
        }

        .banner__poster {
            display: none;
        }
    }

    &:not(.playing) {
        #bannerCloseButton {
            display: none;
        }

        #bannerLinkVideo {
            display: none;
        }
    }

    #bannerPlayButton, #bannerCloseButton {
        display: flex;
        position: absolute;
        align-self: center;
        flex-shrink: 0;
        left: calc(50% - 96px/2);
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 96px;
        height: 96px;
        border-radius: 50%;
        border-color: transparent;
        background-color: #632D92;
        transition: 175ms transform;
        
        @media (max-width: 1024px) {
            left: calc(50% - 72px/2);
            width: 72px;
            height: 72px;
        }
        
        @media (max-width: 768px) {
            left: calc(50% - 48px/2);
            width: 48px;
            height: 48px;
        }

        &:hover {
            transform-origin: center;
            transform: scale(1.1);
        }

        &::after {
            content: "";
            position: relative;
            background-image: url("./../images/play-button.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            left: 4px;
            width: 36px;
            height: 36px;
            display: block;
            opacity: 0.4;
            
            @media (max-width: 1024px) {
                width: 28px;
                height: 28px;
            }
            
            @media (max-width: 768px) {
                width: 20px;
                height: 20px;
                left: 2px;
            }
        }
    }

    #bannerPlayButton {
        @media (max-width: 1024px) {
            align-self: flex-start;
            top: calc(30% - 72px/2);
        }
        
        @media (max-width: 768px) {
            top: calc(30% - 72px/2);
        }
    }

    #bannerCloseButton {
        align-self: flex-start;
        top: 132px;
        left: calc(90% - 96px/2);
        
        &::after {
            background-image: url("./../images/close-icon.svg");
            left: 0;
        }
        
        @media (max-width: 1024px) {
            left: calc(85% - 72px/2);
        }
    }

    &__poster {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: -1;

        &:first-child {
            display: block;
        }

        img {
            top: 0;
            left: 0;
            height: 100vh;
            width: 100vw;
            object-fit: cover;
            object-position: center;
            filter: brightness(75%);
        }
    }

    &--link-video {
        iframe {
            position: absolute;
            box-sizing: border-box;
            height: calc(56.25vw + 200px);
            width: 177.777778vh;
            top: calc(50% - 200px);
            left: 50%;
            min-height: 100%;
            min-width: 100%;
            transform: translate(-50%, calc(-50% + 200px));
            z-index: -1;
            filter: brightness(75%);

            @media (max-width: 768px) {
                display: none;
            }
        }

        #bannerPlayButton, #bannerCloseButton {
            @media (max-width: 768px) {
                display: none;
            }
        }
    }

    &--upload-video {
        video {
            position: absolute;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100vw;
            object-fit: cover;
            object-position: center;
            z-index: -1;
            filter: brightness(75%);
            
            @media (max-width: 768px) {
                display: none;
            }
        }


        .banner__poster {
            display: none;
            
            @media (max-width: 768px) {
                display: block;
            }
        }
    }
}

#bannerUploadVideo {
    &[src=""] + .banner__poster {
        display: block;
    } 
}

#bannerLinkVideo {
    &[data-id=""] + .banner__poster {
        display: block;
    }
}