.accordion {
   border-block: 1px solid $light-purple-20;

   &__button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: rem(24px) rem(8px);
      transition: background 225ms;
      user-select: none;
      cursor: pointer;

      .accordion.active & {
         .icon {
            rotate: 270deg;
         }
      }

      &:hover {
         background-color: $light-purple-20;
      }

      .title {
         font-size: rem(32px);
         line-height: rem(40px);

         @media (max-width: 992px) {
            font-size: rem(24px);
            line-height: rem(32px);
         }
      }

      .icon {
         display: block;
         width: rem(36px);
         height: rem(36px);
         rotate: 90deg;
         transition: rotate 225ms;
      }
   }

   &__wrapper {
      max-height: 0;
      overflow: hidden;
      transition: max-height 225ms;
   }

   &__content {
      padding: rem(32px);
   }
   
   & + & {
      margin-top: -1px;
   }
}

.block.block--accordion {
   h2 {
      margin-bottom: 50px;

      @media (max-width: 1024px) {
         margin-bottom: 32px;
      }
   }
}