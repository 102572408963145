.career-list {
   &__ingress {
      @extend .article;
      
      margin-bottom: 48px;
   }

   &__content {
      --gap-inline: 32px;
      --gap-block: 24px;

      display: flex;
      flex-wrap: wrap;
      gap: var(--gap-block) var(--gap-inline);
      
      .career-card {
         --padding-inline: 32px;
         --padding-block: 28px;
         
         border: 1px solid #7678ed;
         width: 100%;
         padding: var(--padding-block) var(--padding-inline);
         position: relative;
         
         @media (min-width: 1201px) {
            width: calc(33.33% - ((var(--gap-inline) * 2) / 3));
         }
         
         @media (min-width: 768px) and (max-width: 1200px) {
            width: calc(50% - ((var(--gap-inline) * 2) / 2));
         }

         &:hover {
            background-color: #7678ed;

            & .title, & .date, & .description {
               color: #fff;
            }
         }
         
         & * {
            margin-bottom: 0px;
         }

         &__header {
            display: flex;
            align-items: flex-end;
            gap: 8px;
            margin-bottom: 12px;

            .title {
               font-size: 28px;
               line-height: 28px;
            }
            
            .date {
               font-size: 12px;
               line-height: 18px;
               color: #777;
            }
         }

         a {
            position: absolute;
            inset: 0;
         }
      }
   }
}