.text-with-image {
    margin-bottom: 144px;

    @media (max-width: 1024px) {
        margin-bottom: 82px;
    }

    .row {
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    &__content {
        .title {
            font-size: rem(32px);
            line-height: rem(44px);
            margin-bottom: 80px;
            
            @media (max-width: 1024px) {
                font-size: rem(24px);
                line-height: rem(32px);
                margin-bottom: 24px;
            }
        }
        
        .text {
            font-size: rem(23px);
            line-height: rem(36px);
            text-align: justify;

            @media (max-width: 1440px) {
                font-size: rem(20px);
                line-height: rem(32px);
            }

            @media (max-width: 1440px) {
                font-size: rem(17px);
                line-height: rem(26px);
                margin-bottom: 52px;
            }
        }
    }
    
    &__img {
        figure {
            margin-bottom: 24px;

            @media (max-width: 1024px) {
                margin-bottom: 8px;
            }
        }
        legend {
            font-size: rem(16px);
            line-height: rem(24px);
            margin: 0px;

            @media (max-width: 1024px) {
                font-size: rem(12px);
                line-height: rem(18px);
            }
        }
    }
}