.services {
    &__wrapper {
        &--item {
            
            .icon-wrapper {
                @media (max-width: 1024px) {
                    background: none !important;
                }
                @media (min-width: 1025px) {
                
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    
                    img {
                        mask-image: url('./../images/gifs/animated-icon2.svg');
                        -webkit-mask-image: url('./../images/gifs/animated-icon2.svg');
                        mask-mode: alpha;
                        mask-origin: center;
                        -webkit-mask-origin: center;
                        mask-repeat: no-repeat;
                        -webkit-mask-repeat: no-repeat;
                        mask-position: center;
                        -webkit-mask-position: center;
                        mask-size: 350% 350%; 
                        -webkit-mask-size: 350% 350%;
                        transition: all 800ms ease-in-out;
                        
                        &:hover {
                            mask-size: 0% 0%; 
                            -webkit-mask-size: 0% 0%;
                        }
                    }
                }
            }
        }
    }
}