// Grid config
@import "src/assets/sass/base/grid-config";

//import bootstrap
@import "./node_modules/bootstrap/scss/bootstrap";

//Base application
@import "src/assets/sass/base/mixins";
@import "src/assets/sass/base/colors";
@import "src/assets/sass/base/global";
@import "src/assets/sass/base/typography";
@import "src/assets/sass/base/image-aspect-ratio";
@import "src/assets/sass/base/fading-in";
@import "src/assets/sass/base/block";


//components
@import "src/assets/sass/components/buttons";
@import "src/assets/sass/components/header";
@import "src/assets/sass/components/footer";
@import "src/assets/sass/components/main-banner";
@import "src/assets/sass/components/teams";
@import "src/assets/sass/components/page-title";
@import "src/assets/sass/components/image";
@import "src/assets/sass/components/contact-full";
@import "src/assets/sass/components/portfolio";
@import "src/assets/sass/components/article-item";
@import "src/assets/sass/components/article";
@import "src/assets/sass/components/text-with-button";
@import "src/assets/sass/components/title-and-text";
@import "src/assets/sass/components/customers";
@import "src/assets/sass/components/news";
@import "src/assets/sass/components/banner";
@import "src/assets/sass/components/text-with-image";
@import "src/assets/sass/components/content-image-lg";
@import "src/assets/sass/components/text-with-quote";
@import "src/assets/sass/components/small-box";
@import "src/assets/sass/components/header-new";
@import "src/assets/sass/components/services-icon-hover";
@import "src/assets/sass/components/pagination";
@import "src/assets/sass/components/form";
@import "src/assets/sass/components/new-employee";
@import "src/assets/sass/components/video-block";
@import "src/assets/sass/components/image-block";
@import "src/assets/sass/components/career-list";
@import "src/assets/sass/components/square-cards";
@import "src/assets/sass/components/accordion";
@import "src/assets/sass/components/partners-block";

//Pages
@import "src/assets/sass/pages/about-page";
@import "src/assets/sass/pages/services-page";
@import "src/assets/sass/pages/article-page";
@import "src/assets/sass/pages/career-page";
@import "src/assets/sass/pages/vacancy-page";
