.services-page {
    #animatedLine {
        @media (max-width: 1024px) {
            display: none;
        }

        canvas {
            margin-top: -48px;
        }
    }
   
    .image.image--animated {
        @media (max-width: 1024px) {
            overflow: hidden;
        }

        @media (min-width: 1025px) {
            mask-image: url("./../images/gifs/animated-icon2.svg");
            mask-repeat: no-repeat;
            mask-position: center;
            mask-size: 250% 250%;
            -webkit-mask-image: url("./../images/gifs/animated-icon2.svg");
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-position: center;
            -webkit-mask-size: 250% 250%;
            transition: mask-size 600ms;
            -webkit-transition: -webkit-mask-size 600ms;
            &:hover {
                mask-size: 130% 130%;
                -webkit-mask-size: 130% 130%;
            }
        }
    }

    .video {
        // margin-bottom: 250px;

        // @media(max-width:1024px){
        //     margin-bottom: 40px;
        // }
    }

    .row {
        .image, .video {
            .aspectRatio--1133x700 {
                @media (max-width: 1024px) {
                    padding-top: calc((261 / 355) * 100%);
                }
            }
        }
    }
}

.container.services {
    .services__wrapper {
        margin-top: 120px;
        margin-bottom: 0px;
        
        gap: 82px 0;
        
        @media (max-width: 1024px) {
            margin-top: 60px;
            gap: 44px 0;
        }

        &--item {
            text-align: center;

            .title {
                margin-top: 60px;
                margin-bottom: 22px;
                font-size: rem(24px);
                line-height: rem(32px);
                letter-spacing: -1px;

                @media (max-width: 1024px) {
                    margin-top: 0px;
                    margin-bottom: 12px;
                }
            }

            .description {
                max-width: 400px;
                margin-right: auto;
                margin-left: auto;
                font-size: rem(23px);
                line-height: rem(36px);
                margin-bottom: 20px;

                @media (max-width: 1024px) {
                    font-size: rem(17px);
                    line-height: rem(22px);
                    max-width: none;
                    margin-bottom: 12px;
                }
            }

            .read-more {
                font-size: rem(23px);
                line-height: rem(36px);
                color: $purple;

                @media (max-width: 1024px) {
                    font-size: rem(17px);
                    line-height: rem(22px);
                }
            }

            @media (max-width: 1024px) {
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
            }
        }
    }
}

.container.services:not(.services--list) .row.services__wrapper {
    display: flex;

    @media (max-width: 1024px) {
        .services__wrapper--item {
            flex-direction: column;

            .icon-wrapper {
                height: 120px;
                margin-bottom: 24px;

                img {
                    height: 100%;
                }
            }

            .content-wrapper {
                text-align: center;
            }
        }
    }

    @media (max-width: 768px) {
        .services__wrapper--item {
            .icon-wrapper {
                height: 65px;
            }
        }
    }
}

.services.services--list {
    .img-wrapper {
        @media (max-width: 1024px) {
            width: 47%;
            text-align: center;
            flex-shrink: 0;

            img {
                height: 85px;
            }
        }
    }   

    .content-wrapper {
        @media (max-width: 1024px) {
            width: 53%;
            text-align: left;
        }
            
        .title {
            text-align: inherit;
        }
    }
}

.video {
    position: relative;
    width: 100%;
    height: 100%;

    figure {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        
        img {
            object-fit: cover;
            object-position: center;
        }

        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
        }
    }
    

    &__play-button, &__close-button {
        display: flex;
        position: absolute;
        align-self: center;
        flex-shrink: 0;
        top: calc(50% - 96px/2);
        left: 48%;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 96px;
        height: 96px;
        border-radius: 50%;
        border-color: transparent;
        background-color: #632D92;
        transition: 175ms transform;
        
        @media (max-width: 1024px) {
            top: calc(50% - 72px/2);
            width: 72px;
            height: 72px;
        }
        
        @media (max-width: 768px) {
            top: calc(50% - 48px/2);
            width: 48px;
            height: 48px;
        }

        &:hover {
            transform-origin: center;
            transform: scale(1.1);
        }

        &::after {
            content: "";
            position: relative;
            background-image: url("./../images/play-button.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            left: 4px;
            width: 36px;
            height: 36px;
            display: block;
            opacity: 0.4;
            
            @media (max-width: 1024px) {
                width: 28px;
                height: 28px;
            }
            
            @media (max-width: 768px) {
                width: 20px;
                height: 20px;
                left: 2px;
            }
        }
    }

    &__close-button {
        display: none;
        align-self: flex-start;
        top: 48px;
        right: 48px;
        left: initial;
        
        @media (max-width: 1024px) {
            left: initial;
            top: 32px;
            right: 32px;
        }
        
        @media (max-width: 575px) {
            left: initial;
            top: 16px;
            right: 16px;
        }
        
        &::after {
            background-image: url("./../images/close-icon.svg");
            left: 0;
        }
    }

    &.playing {
        .video__play-button {
            display: none;
        }
        
        .video__close-button {
            display: flex;
        }

        figure {
            img {
                display: none;
            }
        }
    }
}