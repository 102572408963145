.footer{
  padding: 88px 80px 56px 80px;
  background-color: #000;

  @media (max-width: 1024px) {
    padding: 56px 20px;
    background-color: #1F1F1F;
  }

  .content-wrapper {
    display: flex;
    align-items: flex-end;

    @media (max-width: 575px) {
      align-items: flex-start;
      flex-direction: column;
    }
    
    .links {
      width: 70%;
      
      @media (max-width: 575px) {
        width: 100%;
      }
    }
    
    .logo {
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      
      @media (max-width: 575px) {
        width: 100%;
        align-items: flex-start;
      }

      figure {
        height: 100%;

        img {
          display: block;
          max-height: 180px;
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
 
  .typography-h6{
    color: $gray-700;
    letter-spacing: -1px;
  }

  .contacts-data{
    margin-top: 32px;
    margin-bottom: 48px;
  
    a{
      color: #FFFFFF;
      display: table;
      font-size: rem(24px);
      line-height: rem(32px);
      letter-spacing: -1px;
      margin-bottom: 24px;
      text-decoration: none;
      position: relative;

      &:after{
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 5px;
        left: 0;
        border-bottom: 1px solid white;
      }
    }
  }
  
  .social-links{
    margin-top: 32px;
    margin-bottom: 48px;

    a{
      color: #FFFFFF;
      text-decoration: none;
      display: table;
      margin-bottom: 24px;
      font-size: rem(24px);
      letter-spacing: -1px;
      line-height: rem(32px);
      position: relative;

      &:after{
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 5px;
        left: 0;
        border-bottom: 1px solid white;
      }

      @media(max-width: 1024px){
        font-size: rem(24px);
        line-height: rem(32px);
      }
    }
  }

  .contacts-data, .social-links {

    a {
        transition: color 175ms;

        &::after {
            transition: border-color 175ms;
        }

        &:hover, &:active {
            color: #BBBBF6;
            
            &::after {
                border-color: #BBBBF6;
            }
        }
    }

    @media (max-width: 1024px) {
        margin: 24px 0px 32px 0px;
        a {
            font-size: rem(18px);
            line-height: rem(24px);
            margin-bottom: 22px;
    
            &::after {
                display: none;
            }
        }
    }
  }
  
  .copyright{
    margin-top: 70px;
    border-top: 1px solid $gray-700;
    padding-top: 20px;

    span, a{
      color: $gray-700;
      display: inline-block;
      margin-right: 32px;
      font-size: rem(16px);
      line-height: rem(24px);
      text-decoration: none;
      
      @media (max-width: 1024px) {
        margin-right: 20px;
      }

      &:last-child {
        margin-right: 0px;
      }
    }

    a {
      transition: 175ms color ease-in-out;

      &:hover {
        color: #fff;
      }
    }

    @media (max-width: 1024px) {
        margin-top: 52px;
    }
  }

}