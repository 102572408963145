.title-and-text{
  .intro{
    h2{
      font-size: rem(48px);
      line-height: rem(60px);
      @media(max-width: 1024px){
        font-size: rem(26px);
        line-height: rem(36px);
      }
    }
    p{
      font-size: rem(24px);
      line-height: rem(36px);
      letter-spacing: -1px;
      @media(max-width: 1024px){
        font-size: rem(20px);
        line-height: rem(32px);
      }
    }
  }
}