.text-with-button {

    .intro {
        p {
            font-size: rem(32px);
            line-height: rem(40px);
            letter-spacing: -1px;
            margin-bottom: 48px;

            @media(max-width: 1024px) {
                font-size: rem(20px);
                line-height: rem(32px);
                margin-bottom: 32px;
            }
        }
    }
}