html{
	-webkit-text-size-adjust: 100%;
	font-size: 100%;
}

body{
	font-family: "Px Grotesk Light",sans-serif;
	margin: 0px;
	padding: 0;
	font-size: 16px;
}

html,body{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

h1, h2, h3, h4, h5, h6, p{
	word-break: break-word;
}

figure{
	margin: 0px;
}

img{
	max-width: 100%;
	//width: 100%;
}

figure.img + .media-description,
.video-block video + .media-description,
.video-block iframe + .media-description {
   display: inline-block;
   position: relative;
   text-decoration: none !important;
   color: #1f1f1f;
   font-size: rem(15px);
   line-height: rem(20px);
   margin-top: 12px;
}

.container-fluid {
   figure.img + .media-description,
   .video-block video + .media-description,
   .video-block iframe + .media-description {
      padding-inline: 30px;
   }
}

.mt-80{
	margin-top: 80px !important;
}

.mb-80{
	margin-bottom: 80px !important;
}

.mt-200{
	margin-top: 200px !important;
}

.mb-200{
	margin-bottom: 200px !important;
}

.mt-103{
	margin-top: 103px !important;
}

.mt-262{
	margin-top: 262px !important;
}

.mt-n70{
	margin-top: -70px !important;
}

.mt-72{
	margin-top: 72px !important;
}

.mt-n210{
	margin-top: -210px !important;
}

.intro{
	p{
		font-size: rem(32px);
		line-height:rem(40px);
		letter-spacing: -1px;
		color: $black;
      margin-bottom: 0;

		@media(max-width: 1024px){
			font-size: rem(20px);
			line-height: rem(32px);
			letter-spacing: -1px;
		}
	}

	h2{
		font-size: rem(48px);
		line-height: rem(56px);
		letter-spacing: -1.7px;
		margin-bottom: 32px;
		color: $black;
		
		@media(max-width: 1024px){
			font-size: rem(26px);
			line-height: rem(36px);
			letter-spacing: -1px;
			margin-bottom: 20px;
		}
	}
}

.frontpage-main {
    position: relative;

    & .animated-bg {
        display: block;
        position: absolute;
        top: 100vh;
        left: 0px;
        height: 100vh;
        opacity: .35;
        z-index: -999;

        @media (max-width: 1024px) {
            display: none;
        }
    }
}

// .services {
	// .intro{
	// 	p{
	// 		font-size: rem(24px);
	// 		line-height:rem(36px);

	// 		@media(max-width: 1024px){
	// 			font-size: rem(20px);
	// 			line-height: rem(32px);
	// 			letter-spacing: -1px;
	// 			margin-bottom: 32px;
	// 		}
	// 	}
	// }
// 	&__wrapper{
//         margin-top: 120px;

//         @media (max-width: 575px) {
//             margin-top: 60px;
//         }

// 		&--item{
// 			text-align: center;
// 			margin-bottom: 104px;

// 			@media(max-width: 1024px){
// 				margin-bottom: 32px;
// 			}

// 			h6{
// 				margin-top: 56px;
// 				font-size: rem(24px);
// 				line-height: rem(32px);
// 				text-align: center;
                
// 				@media(max-width: 1024px){
//                     margin-top: 24px;
// 					font-size: rem(18px);
// 					line-height: rem(24px);
// 					letter-spacing: -0.5px;
// 				}
// 			}

// 			img{
// 				@media(max-width: 1024px){
// 					height: 65px;
// 				}
// 			}
// 		}
// 	}	
// }



.contact{
	.intro{
		p{
            font-size: rem(48px);
            line-height: rem(60px);

			@media(max-width: 1024px){
				font-size: rem(26px);
				line-height: rem(26px);
                margin-bottom: 40px;
			}
		}
	}	

    &__img-wrapper {
        width: 100%;
        position: relative;

        .inside-wrapper {
            &-main {
                width: 83.33%;
                margin-left: auto;
                
                .main-image{
                    @media(max-width: 1024px){
                        // margin-left: auto;
                        // margin-top: -55px;
                    }
                }
            }
            
            &-left {
                position: absolute;
                width: 50%;
                top: 66.66%;

                .image-left{
                    // margin-left: -176px;
                    // max-width: 410px;
                    // margin-top: -174px;
                    z-index: 1;
                    position: relative;
        
                    @media(max-width: 1024px){
                        // max-width: 173px;
                        // margin-left: auto;
                        // margin-right: 118px;
                        // margin-top: calc(-56px - 74px);
                    }
                }
            }
        }
    }
}



.z-index-n1{
	z-index: -1;
}

.bg-blue{
	//background: linear-gradient(180deg, #EDEEFF 0%, rgba(255, 255, 255, 0) 100%);
	// background: rgb(255,255,255);
	// background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(237,238,255,1) 70%);
    background: linear-gradient(180deg, rgba(118, 120, 237, 0) 0%, rgba(118, 120, 237, 0.24) 50.52%, rgba(118, 120, 237, 0) 100%);
}

.navigation{
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 80px;
}

.customCursor {
    pointer-events: none;
    position: fixed;
    z-index: 9999;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: $purple;
    transform: translate(-50%, -50%);

    @media (max-width: 1024px) {
        display: none !important;
    }
}

// Custom cursor hover over images effect

figure {
    position: relative;
    img {
        pointer-events: none;
    }

    & .hoveredCursor {
        pointer-events: none;
        display: none;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 180px;
        height: 180px;
        background-image: url('./../images/gifs/animated-icon2.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        transform: translate(-50%, -50%);
    }

    &:hover{
        & .hoveredCursor {
            display: block;
        }
    }
}

a {
    figure {
        @media (min-width: 1025px) {
            cursor: none;
        }
    }
}

.hoveredCursorText {
    position: relative;
    color: white !important;
    font-weight: 400;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Px Grotesk Light', sans-serif;
    font-size: rem(20px);
    line-height: rem(32px);
    max-width: 50%;
    max-height: 50%;
    margin-bottom: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.header-nav__logo {
    a {
        figure {
            cursor: pointer;
            
            &:hover {
                & .hoveredCursor {
                    display: none;
                }
            }
        }
    }
}

.main-image:hover, .image-left:hover {
    & .hoveredCursor {
        display: none;
    }
}

.pageNotFound {
   section {
      padding-block: 250px;

      @media (max-width: 992px) {
         padding-block: 186px;
      }
   }
   
   &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
      color: #1f1f1f;

      &>* {
         margin: 0;
         color: inherit;
      }

      .icon {
         font-style: normal;
         font-size: rem(40px);
         line-height: rem(52px);
      }

      h1 {
         text-align: center;
         font-size: rem(52px);
         line-height: rem(60px);
         
         @media (max-width: 992px) {
            font-size: rem(40px);
            line-height: rem(52px);
         }

         @media (max-width: 576px) {
            font-size: rem(36px);
         }
      }

      p {
         text-align: center;
         font-size: rem(32px);
         line-height: rem(44px);
         
         @media (max-width: 992px) {
            font-size: rem(23px);
            line-height: rem(36px);
         }

         @media (max-width: 576px) {
            font-size: rem(20px);
            line-height: rem(28px);
         }
      }
   }
}

// Cookie styles override

.CookieDeclaration {
   max-width: 680px !important;
   margin-inline: auto !important;
   padding-inline: 16px !important;
   margin-block: 80px !important;
   font-size: rem(17px) !important;
   line-height: rem(26px) !important;
}