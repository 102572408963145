.contact-full{
  background-color: $light-purple;
  text-align: center;
  padding: 200px 0;

  //margin-left: -15px;
  //margin-right: -15px;
  //width: calc(100% + 30px);

  @media(max-width: 1024px){
    padding: 80px 0;
  }

  p{
    color: #FFFFFF;
    font-size: rem(48px);
    line-height: rem(60px);
    letter-spacing: -1.7%;
    margin-bottom: 0px;

    @media(max-width: 1024px){
      font-size: rem(24px);
      line-height: rem(32px);
      letter-spacing: -1px;
    }
  }

   a {
      color: #FFFFFF;
      font-size: rem(48px);
      line-height: rem(60px);
      letter-spacing: -1.7%;
      margin-bottom: 0px;
  
      @media(max-width: 1024px){
        font-size: rem(24px);
        line-height: rem(32px);
        letter-spacing: -1px;
      }

      &:hover {
         color: #FFFFFF;
      }
   }


   &--dark {
      background-color: #222222;
   }

   &--light {
      background-color: #EFEAF4;

      p, a {
         color: #1F1F1F;
         
         &:hover {
            color: #1F1F1F;
         }
      }
   }
}