.pagination {   
   @media (min-width: 769px) {
      max-width: 540px;
      margin-inline: auto;
   }
   
   @media (min-width: 1025px) {
      max-width: 1520px;
   }

   &__wrapper {
      display: flex;
      gap: 8px;
      list-style: none;
      justify-content: flex-end;
      padding: 0;
      margin-bottom: 0;

      li {
         position: relative;

         a {
            position: relative;
            font-size: rem(21px);
            line-height: rem(28px);
            text-decoration: none;
            color: #1f1f1f;
            padding: 8px 4px;

            @media (max-width: 768px) {
               font-size: rem(17px);
               line-height: rem(26px);
            }

            &:not(#paginationPrevious):not(#paginationNext) {
               &::after {
                  content: "";
                  display: block;
                  position: absolute;
                  bottom: -2px;
                  left: 50%;
                  right: 50%;
                  height: 2px;
                  background-color: #632D92;
                  transition: 175ms left, 175ms right;
               }
   
               &.currentPage {
                  font-weight: 700;
                  &::after {
                     left: 0;
                     right: 0;
                  }
               }

               &:hover {
                  &::after {
                     left: 0;
                     right: 0;
                  }
               }
            }
         }

         #paginationPrevious {
            margin-left: 24px;

            &::after {
               content: "";
               background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.91248 14.325L10.7291 10.5L6.91248 6.675L8.08748 5.5L13.0875 10.5L8.08748 15.5L6.91248 14.325Z' fill='%23213744'/%3E%3C/svg%3E");
               width: 20px;
               height: 20px;
               display: block;
               position: absolute;
               right: 100%;
               top: calc(50% - 11px);
               transform: rotate(180deg);
               transition: 175ms right;
            }

            &:hover:not([disabled="true"]) {
               &::after {
                  right: calc(100% + 8px);
               }
            }
         }
         
         #paginationNext {
            margin-right: 24px;

            &::after {
               content: "";
               background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.91248 14.325L10.7291 10.5L6.91248 6.675L8.08748 5.5L13.0875 10.5L8.08748 15.5L6.91248 14.325Z' fill='%23213744'/%3E%3C/svg%3E");
               width: 20px;
               height: 20px;
               display: block;
               position: absolute;
               left: 100%;
               top: calc(50% - 11px);
               transition: 175ms left;
               }

            &:hover:not([disabled="true"]) {

               &::after {
                  left: calc(100% + 8px);
               }
            }
         }

         #paginationPrevious, #paginationNext {
            &[disabled = "true"] {
               display: none;
            }
         }
      }
   }
}