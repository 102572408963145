.teams {
   gap: 80px 0;
   
   @media (max-width: 1024px) {
      gap: 80px 0;
   }
   
    .team {
      //   margin-bottom: 80px;
        padding-left: 14px !important;
        padding-right: 14px !important;
        
        @media(max-width: 1024px) {
            // margin-bottom: 40px;
            padding-left: 10px !important;
            padding-right: 10px !important;
        }

        figure {
            @media (min-width: 1025px) {
               //  cursor: none;
            }
            img {
                width: 100%;
            }

        }
    }
}

.team-member-info {
    color: #1f1f1f;

    & * {
        color: inherit;
    }
    
    p, a {
        font-size: rem(28px);
        line-height: rem(36px);
        letter-spacing: -1px;
        margin-bottom: 4px;
        text-decoration: none;

        &:last-child {
            margin-bottom: 0px;
        }

        @media(max-width: 1024px) {
            font-size: rem(20px);
            line-height: rem(32px);
            margin-bottom: 0;
        }

        a {
            font-size: inherit;
            font-weight: inherit;
            margin: 0;
        }
    }

    p {
        position: relative;
        
        &:first-child {
            font-family: "Px Grotesk Regular";
            margin-top: 24px;

            @media(max-width: 1024px) {
                margin-top: 14px;
            }
        }

        a {
            transition: color 175ms ease-in-out;

            &:hover {
                color: #632D92;
            }
        }
    }

    p {
        & > .emailTooltip {
            position: absolute;
            background-color: #632D92;
            color: #fff;
            font-size: 14px;
            line-height: 22px;
            bottom: calc(100%);
            left: 50%;
            right: 50%;
            transition: right 175ms ease-in-out, left 175ms ease-in-out;
            white-space: nowrap;
            overflow: hidden;
            text-align: center;
        }

        &.copied {
            & > .emailTooltip {
                left: 0;
                right: 0;
            }
        }
        
        .team-member-email {
            position: relative;
            display: inline-block;
            cursor: pointer;
        }
    }
}

.teams-maskbg {
    position: relative;
    background-position: center;
    background-size: 100%;

    .teams-img-wrapper:not([data-hover-text=""]) {
        position: relative;

        @media (min-width: 1025px) {
            mask-image: url('./../images/gifs/animated-icon2.svg');
            -webkit-mask-image: url('./../images/gifs/animated-icon2.svg');
            mask-mode: alpha;
            -webkit-mask-mode: alpha;
            mask-size: 500% 500%;
            -webkit-mask-size: 500% 500%;
            mask-origin: top left;
            -webkit-mask-origin: top left;
            mask-repeat: no-repeat;
            -webkit-mask-repeat: no-repeat;
            mask-position: center;
            -webkit-mask-position: center;
            transition: all 500ms;
            
            &:hover {
                mask-position: center;
                -webkit-mask-position: center;
                mask-size: 115% 95%;
                -webkit-mask-size: 115% 95%;
            }
        }

        .blackShape {
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #000;
        }

        .teamMemberDescription {
            pointer-events: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 75%;
            transition: visibility 0ms ease-in-out;
            transition-delay: 500ms;
            color: #fff;
            font-size: rem(16px);
            line-height: rem(24px);
        }
    }
}

.contact-page {
    .team {
        margin-bottom: 0px;
    }

    .teams {
        @media (max-width: 1024px) {
            margin-bottom: 105px;
        }
    }
}