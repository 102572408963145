.block {
   &.block--index-page-cover,
   &.block--career-page-banner,
   &.block--vacancy-page-banner {
      margin-top: 0px !important;
   }
   
   &--form, &--form-confirmation {
      margin-block: 60px;
      
      @media (max-width: 1024px) {
         margin-block: 40px;
      }
   }

   margin-block: 150px;

   @media (max-width: 1024px) {
      margin-block: 60px;
   }

   &--contact:last-child {
      margin-bottom: 0;
   }

   &--index-page-overlap-image {
      margin-bottom: calc(150px + 103px);
   
      @media (max-width: 1024px) {
         margin-bottom: calc(105px + 59px);
      }
   }

   &--front-page-hero {
      .title-and-text {
         margin-bottom: 120px;
         
         @media (max-width: 1024px) {
            margin-bottom: 40px;
         }
      }
   }

   &--index-page-customers-banner {
      margin-block: 250px;

      @media (max-width: 1024px) {
         margin-block: 80px;
      }   
   }
}

.hero-block + .block.block--video, .hero-block + .block.block--video-external {
   margin-top: 120px;
   
   @media (max-width: 1024px) {
      margin-top: 40px;
   }
}