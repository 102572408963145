.header-nav {
    position: absolute;
    z-index: 2;
    padding-top: 40px;
    left: 50%;
    transform: translateX(-50%);

    &--white {
        color: #fff;
    }

    &--black {
        position: relative;
        left: 0px;
        transform: none;
        color: #1f1f1f;

        .header-nav__mobile-btn {
            a {
                div {
                    background-color: #1f1f1f;
                }
            }
        }
    }

    &__logo {
        width: auto;
        transition: opacity 300ms ease-in-out;

        a {
            display: flex;
            align-items: center;
            gap: 12px;
            text-decoration: none;
            color: white;

            figure {
                width: 109px;

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }

            div {
                font-size: 15px;
                font-family: "Px Grotesk Regular";
            }

            &.flex-row {
                align-items: baseline;
            }
        }
    }

    &__links {
        width: auto;

        @media (max-width: 1075px) {
            display: none;
        }

        ul {
            list-style: none;
            margin: 0px;
            display: flex;

            li {
                font-size: rem(21px);
                line-height: rem(28px);

                &:not(:last-child) {
                    margin-right: 40px;
                }

                a {
                    position: relative;
                    color: inherit;
                    text-decoration: none;

                    &::after {
                        content: "";
                        position: absolute;
                        height: 2px;
                        top: calc(100% + 8px);
                        left: 50%;
                        right: 50%;
                        background-color: #fff;
                        transition:
                            right 175ms ease-in-out,
                            left 175ms ease-in-out;
                    }

                    &.site-translation {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 6px;

                        i {
                            position: relative;
                            font-size: 20px;
                            top: -1px;
                        }
                    }
                }

                &:hover {
                    a::after {
                        left: 0;
                        right: 0;
                    }
                }
            }
        }
    }
}

.mobile-btn {
    position: absolute;
    top: 40px;
    left: calc(100% - 80px);
    z-index: 3;
    display: none;
    width: auto;

    @media (max-width: 1075px) {
        display: block;
    }

    .btn-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 42px;
        height: 42px;
        background-color: transparent;
        border: none;

        span {
            width: 29px;
            height: 2px;
            background-color: #fff;
            transition:
                transform 300ms ease-in-out,
                background-color 600ms ease-in-out;

            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
    }

    &.active {
        .btn-wrapper {
            span {
                background-color: #fff !important;
                &:nth-child(2) {
                    opacity: 0;
                }

                &:first-child {
                    transform: rotate(45deg) translate(7px, 8px);
                }

                &:last-child {
                    transform: rotate(-45deg) translate(6px, -8px);
                }
            }
        }
    }
}

.header-nav--black + .mobile-btn {
    .btn-wrapper {
        span {
            background-color: #1f1f1f;
        }

        &.active {
            span {
                background-color: #fff;
            }
        }
    }
}

.menu-mobile {
    position: absolute;
    z-index: 2;
    margin: 0px;
    padding: 0px;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.95);

    transform: translateX(-100%);
    opacity: 0;
    transition:
        transform 500ms ease-in-out,
        opacity 500ms ease-in-out;
    transition-delay: 150ms;

    &.active {
        opacity: 1;
        transform: translateX(0%);
    }

    ul {
        padding-top: 40px;
        padding-left: 38px;
        margin: 0px;
        li {
            font-size: rem(24px);
            line-height: rem(32px);
            list-style: none;
            &:not(:last-child) {
                margin-bottom: 26px;
            }

            a {
                color: #fff;
                text-decoration: none;

                &.site-translation {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 6px;

                    i {
                        position: relative;
                        font-size: 20px;
                        top: -1px;
                    }
                }
            }
        }
    }
}

.header-nav--black {
    .header-nav__links {
        ul {
            li {
                a::after {
                    background-color: #1f1f1f;
                }
            }
        }
    }
}
