.article-page {
    margin-top: 200px;
    margin-bottom: 80px;
    
    @media (max-width: 1024px) {
        margin-top: 80px;
    }

    color: #000;

    & * {
        color: inherit !important;
    }
}

.articles-page {
    a {
        text-decoration: none;
    }

    &.container {
        @media (min-width: 769px) {
            max-width: 540px;
        }

        @media (min-width: 1025px) {
            max-width: 1520px;
        }
    }

    .article-item {
        figure {
            overflow: hidden;
        }

        h2 {
            color: black;
        }
    }
}

.articles-page.container+.contact-full {
    @media (min-width: 1025px) {
        display: none;
    }
}

.articleDate {
    display: block;
    text-align: right;
    margin-bottom: 64px;
    font-size: rem(20px);
    line-height: rem(28px);
    color: #969696 !important;

    @media (max-width: 1024px) {
        font-size: rem(12px);
        line-height: rem(14px);
        margin-bottom: 32px;
    }
}

hr.topDivider {
    margin-bottom: 8px;
    margin-top: 0;
}

hr.bottomDivider {
    margin-top: 0px;
    margin-bottom: 181px;

    @media (max-width: 1024px) {
        margin-bottom: 62px;
    }
}

.other-articles--with-header {
    .bottomDivider {
        margin-bottom: 68px;
    }
}

.box:last-child {
    margin-bottom: 0px;
}

.other-articles {
    margin-bottom: 150px;

    @media (max-width: 1024px) {
        margin-bottom: 118px;
    }

    &__header {
        display: none;
        font-size: rem(32px);
        line-height: rem(44px);
        margin-bottom: 32px;
        color: #1f1f1f;
        
        & > * {
            margin: 0;
        }
        
        @media (max-width: 1024px) {
            font-size: rem(24px);
            line-height: rem(32px);
            margin-bottom: 24px;
        }

        @media (min-width: 769px) and (max-width: 1024px) {
            max-width: 540px;
            margin-inline: auto;
        }
    }

    &--with-header {
        .other-articles__header {
            display: block;
        }
    }

    .intro {
        p {
            font-size: rem(21px);
            line-height: rem(28px);
            margin-bottom: 0px;
        }
    }

    .article-item {
        a {
            text-decoration: none;
        }

        @media (min-width: 769px) {
            max-width: 540px;
            margin-left: auto;
            margin-right: auto;
        }

        @media (min-width: 1025px) {
            max-width: 1520px;
        }

        @media (max-width: 1024px) {
            &:not(:last-child) {
                margin-bottom: 46px;
            }
        }

        figure {
            overflow: hidden;
            margin-bottom: 24px;

            @media (max-width: 768px) {
                margin-bottom: 12px;
            }
        }

        h2 {
            color: black;
            font-size: rem(24px);
            line-height: rem(32px);
            margin-bottom: 14px;
            
            @media (max-width: 768px) {
                font-size: rem(18px);
                line-height: rem(28px);
                margin-bottom: 8px;
            }
        }

        p {
            font-size: rem(21px);
            line-height: rem(28px);
            color: #616161;
            
            @media (max-width: 768px) {
                font-size: rem(17px);
                line-height: rem(26px);
            }
        }
    }
}

.articlePageGradientBG {
    position: absolute;
    top: 0;
    left: 0;
    height: 1800px;
    width: 100%;
    background: linear-gradient(180deg, rgba(237,238,255,1) 0%, rgba(237,238,255,1) 50%, rgba(255,255,255,1) 100%);
    z-index: -99;
    
    @media (max-width: 1024px) {
        display: none;
    }
}