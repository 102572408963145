$grid-columns: 12 !default;
$grid-gutter-width: 32px !default;

$grid-breakpoints: (
		xxs: 0,
    xs: 320px,
    sm: 576px,
    md: 768px,
    lg: 1025px,
    xl: 1200px,
    xxl: 1440px
) !default;

$container-max-widths: (
	xs: 390px,
  sm: 540px,
  md: 1024px,
  lg: 1200px,
  xl: 1440px,
  xxl: 1590px
) !default;

.container-fluid{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media (min-width: 1025px){
  .container{
      padding-left: 30px !important;
      padding-right: 30px !important;
  }
}

main{
  overflow: hidden;
}