// Image aspect ratio
.img,
.text {
  position: relative;
  width: 100%;
  overflow: hidden;

  img, > iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
  }

  > h3 {
    position: absolute;
    top: 0;
    left: 50%;
    height: auto;
    width: 100%;
    transform: translate(-50%, 0);
  }

  iframe{
    height: 100%;
    width: 100%;
  }

  &.aspectRatio--1x1 {
    padding-top: calc((1 / 1) * 100%);
  }
  &.aspectRatio--4x3 {
    padding-top: calc((3 / 4) * 100%);
  }
  &.aspectRatio--16x9 {
    padding-top: calc((9 / 16) * 100%);
  }

  &.aspectRatio--1007x643 {
    padding-top: calc((643 / 1007) * 100%);
  }

  &.aspectRatio--494x315 {
    padding-top: calc((315 / 494) * 100%);
  }

  &.aspectRatio--622x454 {
    padding-top: calc((454 / 622) * 100%);
  }

  &.aspectRatio--494x615 {
    padding-top: calc((615 / 494) * 100%);
  }

  &.aspectRatio--622x480 {
    padding-top: calc((480 / 622) * 100%);
  }

  &.aspectRatio--410x278 {
    padding-top: calc((278 / 410) * 100%);
  }

  &.aspectRatio--335x300 {
    padding-top: calc((300 / 335) * 100%);
  }

  &.aspectRatio--200x200 {
    padding-top: calc((200 / 200) * 100%);
  }

  &.aspectRatio--1680x840 {
    padding-top: calc((840 / 1680) * 100%);
  }
  
  &.aspectRatio--359x519 {
    padding-top: calc((519 / 359) * 100%);
  }

  &.aspectRatio--600x300 {
    padding-top: calc((300 / 600) * 100%);
  }

  &.aspectRatio--1133x700 {
    padding-top: calc((700 / 1133) * 100%);
  }

  &.aspectRatio--488x615 {
    padding-top: calc((615 / 488) * 100%);
  }
  &.aspectRatio--488x359 {
    padding-top: calc((359 / 488) * 100%);
  }
  &.aspectRatio--488x608 {
    padding-top: calc((608 / 488) * 100%);
  }
  &.aspectRatio--1262x714 {
    padding-top: calc((714 / 1262) * 100%);
  }
  &.aspectRatio--388x278 {
    padding-top: calc((278 / 388) * 100%);
  }
  &.aspectRatio--376x304 {
    padding-top: calc((278 / 388) * 100%);
  }
  &.aspectRatio--750x499 {
    padding-top: calc((750 / 499) * 100%);
  }
}

.img, .image-left {
    &.rounded {
        border-radius: 30px !important;
    }
}
