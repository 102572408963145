.header{
  width: 100%;
  padding-top: 40px;

  .logo{
    figure{
      margin: 0;
    }
  }

  .mobile-menu{
    text-align: right;
  }

  menu{
    list-style: none;
    padding: 0;
    text-align: right;
    li{
      display: inline-block;
      margin: 0 20px;
      position: relative;
      a{
        color: $black;
        text-decoration: none;
        font-size: rem(21px);
        
        &:after{
          content: "";
          border-bottom: 2px solid transparent;
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: 0;
          left: 0;
          transform: scaleX(0);  
          transition: transform 250ms ease-in-out;
        }

        &:hover{
          &:after{
            border-color: $black;
            transform: scaleX(1);
          }
        }
      }
    }
  }

  &.header-float{
    position: absolute;
    padding-top: 0;
    top: 40px;
    left: 50%;
    z-index: 999;
    transform: translateX(-50%);
    menu{
      li{
        a{
          color: #FFFFFF;
          &:hover{
            &:after{
              border-color: $white;
            }
          }
        }
      }
    }
  }

}