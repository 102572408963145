.animated-btn {
    position: relative;

    &__under {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        color: #000;

        &:hover {
            color: #000;
        }
    }

    &__maskwrapper {
        pointer-events: none;
        position: absolute;
        top: 0px;
        left: 0px;
        mask-image: url('./../images/btn-mask.png');
        mask-size: 19px;
        mask-position: center;
        mask-repeat: no-repeat;
        -webkit-mask-image: url('./../images/btn-mask.png');
        -webkit-mask-size: 19px;
        -webkit-mask-position: 20px 15px;
        -webkit-mask-repeat: no-repeat;
        transition: mask 800ms ease-in-out;
        -webkit-transition: -webkit-mask 800ms ease-in-out;
    }

    &__over {
        pointer-events: none;
        background-color: $purple;
        color: #fff;
        position: absolute;
        top: 0px;
        left: 0px;
    }
}

.animated-btn__under:hover + .animated-btn__maskwrapper {
    mask-size: 700px;
    mask-position: -350px -350px;
    -webkit-mask-size: 700px;
    -webkit-mask-position: -350px -350px;
}

.btn {
    border-radius: 50px;
    border: 1px solid $black;
    overflow: hidden;
    font-size: rem(20px);
    line-height: rem(36px);
    letter-spacing: -0.5px;
    padding-left: 51px;
    padding-right: 20px;
    position: relative;
    min-height: 50px;
    transition: color 600ms ease;

    -webkit-appearance: none;

    @media (max-width: 1024px) {
        padding: 10px 24px 8px 48px;
        font-size: rem(18px);
        line-height: rem(28px);
    }

    &.btn-no-bullet {
        padding: 14px 40px;

        &:before {
            display: none;
        }
    }

    &.btn-purple-outline {
        border-color: $purple;
    }
}