.content-image-lg {
    margin-bottom: 144px;

    .title {
        font-size: rem(32px);
        line-height: rem(44px);
        margin-bottom: 24px;
    }

    .subtitle {
        font-family: 'Px Grotesk', sans-serif;
        font-size: rem(23px);
        line-height: rem(36px);
        margin-bottom: 24px;
    }

    .text {
        font-family: 'Px Grotesk Light', sans-serif;
        font-size: rem(23px);
        line-height: rem(36px);
        margin-bottom: 48px;
    }

    figure {
        margin-bottom: 24px;
    }

    legend {
        font-size: rem(16px);
        line-height: rem(24px);
    }

    @media (max-width: 1024px) {
        margin-bottom: 82px;

        .title {
            font-size: rem(24px);
            line-height: rem(32px);
            margin-bottom: 12px;
        }

        .subtitle {
            font-size: rem(18px);
            line-height: rem(26px);
            margin-bottom: 12px;
        }

        .text {
            font-size: rem(17px);
            line-height: rem(26px);
            margin-bottom: 52px;
        }

        figure {
            margin-bottom: 8px;
        }

        legend {
            font-size: rem(12px);
            line-height: rem(18px);
        }
    }
}