.text-with-quote {
    margin-bottom: 144px;

    .title {
        font-size: rem(32px);
        line-height: rem(44px);
        margin-bottom: 24px;
    }

    .subtitle {
        font-family: 'Px Grotesk', sans-serif;
        font-size: rem(23px);
        line-height: rem(36px);
        margin-bottom: 24px;
    }

    .text {
        font-family: 'Px Grotesk Light', sans-serif;
        font-size: rem(23px);
        line-height: rem(36px);
        text-align: justify;
        &:not(:last-child) {
            margin-bottom: 38px;
        }
    }

    .quote {
        margin-bottom: 0px;
        
        p {
            font-family: "Px Grotesk Regular";
            font-size: rem(32px);
            line-height: rem(44px);
            letter-spacing: -1px;
            color: $purple;
            text-align: justify;
            margin-bottom: 0px;
        }

        &:before{
            content: "";
            background-image: url("./../images/quote.svg");
            background-size: cover;
            width: 24.5px;
            height: 17px;
            display: block;
            margin-bottom: 20px;
        }
    }

    @media (max-width: 1024px) {
        margin-bottom: 82px;

        .title {
            font-size: rem(24px);
            line-height: rem(32px);
            margin-bottom: 12px;
        }
        
        .subtitle {
            font-size: rem(18px);
            line-height: rem(26px);
            margin-bottom: 12px;
        }

        .text {
            font-size: rem(17px);
            line-height: rem(26px);
            &:not(:last-child) {
                margin-bottom: 28px;
            }
            &:last-child {
                margin-bottom: 82px;
            }
        }

        .quote {
            p {
                font-size: rem(22px);
                line-height: rem(32px);
            }

            &::before {
                margin-bottom: 12px;
            }
        }
    }
}