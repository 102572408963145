.article {
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        letter-spacing: -1px;
        line-height: 1.3;
    }

    h1, .h1 {
        font-size: rem(72px);
    }

    h2, .h2 {
        font-size: rem(64px);
    }

    h3, .h3 {
        font-size: rem(52px);
    }

    h4, .h4 {
        font-size: rem(40px);
    }

    h5, .h5 {
        font-size: rem(32px);
    }

    h6, .h6 {
        font-size: rem(24px);
    }

    & > * {        
        margin-block: 84px !important;
        
        @media (max-width: 1024px) {
            margin-block: 48px !important;
        }

        &:first-child {
            margin-top: 0px !important;
        }
        
        &:last-child {
            margin-bottom: 0px !important;
        }
    }

    .h1, .h2, .h3 {
        & + p,
        & + ul,
        & + ol,
        & + .quote {
            margin-top: calc(20px - 84px) !important;
            
            @media (max-width: 1024px) {
                margin-top: calc(20px - 48px) !important;
            }
        }
    }

    .h4, .h5, .h6 {
        & + p {
            margin-top: calc(16px - 84px) !important;

            @media (max-width: 1024px) {
                margin-top: calc(20px - 48px) !important;
            }
        }
    }

    p {
        font-size: rem(23px);
        line-height: rem(36px);
        letter-spacing: -1px;
        
        @media (max-width: 768px) {
            font-size: rem(17px);
            line-height: rem(26px);            
        }

        & + p, & + ul, & + ol, & + .quote {
            margin-top: calc(48px - 84px) !important;
            
            @media (max-width: 1024px) {
                margin-top: calc(24px - 48px) !important;
            }
        }
    }

    // h3,
    // h4,
    // h5,
    // h6 {
    //     font-size: rem(32px);
    //     line-height: rem(44px);
    //     margin-bottom: 20px;
    //     letter-spacing: -1px;
        
    //     @media (max-width: 768px) {
    //         font-size: rem(24px);
    //         line-height: rem(32px);
    //         margin-bottom: 24px;

    //     }
    // }
    
    figure {
        margin-bottom: 80px;
        
        figcaption {
            display: inline-block;
            position: relative;
            text-decoration: none !important;
            color: #1f1f1f;
            font-size: rem(15px);
            line-height: rem(20px);
            margin-top: 12px;
        }
    }

    // ul {
    //     list-style-type: "-";
    //     padding-left: 10px;

    //     li {
    //         font-family: "Px Grotesk Regular";
    //         font-size: rem(23px);
    //         line-height: rem(36px);
    //         letter-spacing: -1px;
    //         padding-left: 10px;
    //     }
    // }
    
    .quote {
        margin-bottom: 80px;

        &:before {
            content: "";
            background-image: url("./../images/quote.svg");
            background-size: cover;
            width: 24.5px;
            height: 17px;
            display: block;
            margin-bottom: 20px;
        }
        
        p {
            font-family: "Px Grotesk Regular";
            font-size: rem(32px);
            line-height: rem(44px);
            letter-spacing: -1px;
            color: $purple !important;
            
            @media (max-width: 768px) {
                font-size: rem(24px);
                line-height: rem(32px);
            }
        }
    }
    
    .box {
        background-color: #EDEEFF;
        padding: 60px 41.5px;
        margin-bottom: 80px;
        
        p {
            font-size: rem(20px);
            line-height: rem(32px);
            letter-spacing: -1px;
            margin-bottom: 30px;
            
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    
    ul {
        list-style: none;
        padding-left: 32px;
        margin: 40px 0px;
        
        @media (max-width: 1024px) {
            padding-left: 24px;
        }
        
        li {
            position: relative;
            padding-left: 0px;
            font-size: rem(23px);
            line-height: rem(36px);
            font-family: "Px Grotesk Light", sans-serif;
            
            @media (max-width: 768px) {
                font-size: rem(17px);
                line-height: rem(26px);            
            }
            
            
            &:not(last-child) {
                margin-bottom: 20px;
            }
            
            p {
                margin: 0px;
            }

            &::before {
                content: '';
                display: block;
                width: 12px;
                height: 12px;
                position: absolute;
                top: 12px;
                left: -32px;
                background-color: $purple;
                border-radius: 50%;
                
                @media (max-width: 1024px) {
                    width: 8px;
                    height: 8px;
                    top: 10px;
                    left: -24px;
                }
            }
        
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    img {
        object-fit: contain;
    }

    figure + .media-description {
        margin-top: calc(12px - 80px) !important;
        
        @media (max-width: 1024px) {
            margin-top: calc(12px - 48px) !important;
        }
    }
}