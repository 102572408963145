.form {
   &__intro {
      margin-bottom: 80px;

      @media (max-width: 1024px) {
         margin-bottom: 48px;
      }

      .title {
         margin-bottom: 42px;
         font-size: rem(80px);
         line-height: rem(90px);
         letter-spacing: -2.5%;
         color: #1F1F1F;
         width: 100%;

         @media(max-width:1024px) {
            font-size: rem(32px);
            line-height: rem(44px);
            letter-spacing: -1px;
            margin-bottom: 20px;
         }
      }

      .subtitle {
         font-size: rem(32px);
         line-height: rem(44px);
         letter-spacing: -1px;

         @media(max-width:1024px) {
            font-size: rem(20px);
            line-height: rem(32px);
            letter-spacing: -1px;
         }
      }
   }

   &__intro.centered {
      margin-inline: auto;
      text-align: center;
   }

   &__content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 38px 16px;

      @media (max-width: 1024px) {
         gap: 28px 12px;
      }

      .item {
         position: relative;
         min-width: calc(50% - 16px/2);
         max-width: 100%;
         flex-grow: 1;

         @media (max-width: 1024px) {
            min-width: calc(50% - 12px/2);
         }

         &--large {
            width: 100%;
         }

         span {
            display: block;
            font-size: rem(20px);
            line-height: rem(28px);
            margin-bottom: 1rem;

            @media (max-width: 1024px) {
               font-size: rem(17px);
               line-height: rem(24px);
            }
         }

         input,
         textarea {
            width: 100%;
            padding: 5px 8px;
            border: 1px solid #632D9244;

            &:focus {
               outline: none;
               border-bottom: 1px solid #632D92;
            }

            @media (min-width: 768px) {
               padding-block: 10px;
            }
         }
      }
   }

   &__submit {
      display: flex;
      align-items: center;
      justify-content: center;

      button {
         padding: 8px 24px;
         margin-top: 48px;
         border-radius: 50px;
         border: 1px solid #000;
         background-color: transparent;
         color: #000;
         font-size: rem(20px);
         line-height: rem(28px);
         transition: background-color 175ms, color 175ms;

         @media (max-width: 1024px) {
            padding: 6px 18px;
            font-size: rem(17px);
            line-height: rem(24px);
         }

         &:hover {
            color: #fff;
            background-color: #632D92;
         }

         &.loading {
            pointer-events: none;
            position: relative;
            background-color: #632D92;

            span {
               opacity: 0;
            }

            &::before {
               content: "";
               display: block;
               background-image: url("../images/loading.svg");
               background-position: center;
               position: absolute;
               transform-origin: 50%;
               translate: -50% -50%;
               left: 50%;
               top: 50%;
               width: 22px;
               aspect-ratio: 1;
               background-size: 22px;

               -webkit-animation: loading-spin 2s infinite linear;
               -moz-animation: loading-spin 2s infinite linear;
               -o-animation: loading-spin 2s infinite linear;
               -ms-animation: loading-spin 2s infinite linear;
               animation: loading-spin 2s infinite linear;

               @keyframes loading-spin {
                  0% {
                     rotate: 0deg;
                  }

                  100% {
                     rotate: 360deg;
                  }
               }

               @-moz-keyframes loading-spin {
                  0% {
                     -moz-rotate: 0deg;
                  }

                  100% {
                     -moz-rotate: 360deg;
                  }
               }

               @-o-keyframes loading-spin {
                  0% {
                     -o-rotate: 0deg;
                  }

                  100% {
                     -o-rotate: 360deg;
                  }
               }

               @-ms-keyframes loading-spin {
                  0% {
                     -ms-rotate: 0deg;
                  }

                  100% {
                     -ms-rotate: 360deg;
                  }
               }
            }
         }
      }
   }

   &__error {
      color: red;
   }
}

.form-confirmation {
   &__title,
   &__description {
      text-align: center;
   }

   &__title {
      p {
         margin-bottom: 42px;
         font-size: rem(80px);
         line-height: rem(90px);
         letter-spacing: -2.5%;
         color: #1F1F1F;
         width: 100%;

         @media(max-width:1024px) {
            font-size: rem(32px);
            line-height: rem(44px);
            letter-spacing: -1px;
            margin-bottom: 20px;
         }
      }
   }

   &__description {
      p {
         font-size: rem(28px);
         line-height: rem(36px);
         letter-spacing: -1px;
         margin-bottom: 0;

         @media(max-width:1024px) {
            font-size: rem(20px);
            line-height: rem(32px);
            letter-spacing: -1px;
         }
      }
   }

   &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 48px;

      button {
         padding: 8px 24px;
         border-radius: 50px;
         border: 1px solid #000;
         background-color: transparent;
         color: #000;
         font-size: rem(20px);
         line-height: rem(28px);
         transition: background-color 175ms, color 175ms;

         @media (max-width: 1024px) {
            padding: 6px 18px;
            font-size: rem(17px);
            line-height: rem(24px);
         }

         &:hover {
            color: #fff;
            background-color: #632D92;
         }
      }
   }
}