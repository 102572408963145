.employee-list {
   &__intro {
      h2 {
         font-size: 24px;
         margin: 0;
      }
   }

   &__content {
      padding-top: 60px;
   }

   .employee {
      position: relative;
      display: flex;
      align-items: center;
      color: $black;
      padding-block: 32px;
      
      @media (max-width: 890px) {
         overflow: hidden;
         gap: 24px;
      }

      @media (max-width: 524px) {
         gap: 16px;
      }

      &:hover {
         color: $light-purple;

         @media (min-width: 891px) {
            .employee__name, .employee__description {
               left: 14px;
            }
         }
      }

      & * {
         color: inherit;
      }

      &__image {
         display: block;
         position: relative;
         aspect-ratio: 4/6;
         min-width: 120px;
         max-width: 120px;
         
         @media (max-width: 524px) {
            min-width: 98px;
            max-width: 98px;
         }
         
         @media (min-width: 891px) {
            position: absolute;
            display: none;
            min-width: 400px;
            max-width: 400px;
            pointer-events: none;
         }

         img {
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
      }
      
      &__name {
         display: inline-block;
         position: relative;
         font-size: rem(38px);
         line-height: 2;
         font-weight: bold;
         left: 0;
         transition-property: left, color;
         transition: 175ms ease-in-out;
         cursor: default;
         
         @media (max-width: 890px) {
            line-height: 1.4;
            font-size: rem(22px);
         }
         
         @media (max-width: 524px) {
            line-height: 1.4;
            font-size: rem(16px);
         }
      }
      
      &__description {
         display: inline-block;
         position: relative;
         font-size: rem(24px);
         display: flex;
         flex-direction: row;
         gap: 20px;
         left: 0;
         transition-property: left, color;
         transition: 175ms ease-in-out;
         
         @media (max-width: 890px) {
            font-size: rem(22px);
            flex-direction: column;
            gap: 0px;
         }
         
         @media (max-width: 524px) {
            font-size: rem(16px);
         }

         & > * {
            display: block;
         }

         a {
            text-decoration: none;

            &:hover {
               text-decoration: underline;
            }

            @media (max-width: 890px) {
               text-decoration: underline;
            }
         }

         & > div {
            cursor: default;
         }
      }

      &.active {
         .employee__image {
            @media (min-width: 891px) {
               display: block;
            }
         }
      }
   }
}