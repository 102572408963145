.square-card-group {
   $square-cards-gap: 1rem;

   display: flex;
   align-items: center;
   justify-content: center;
   flex-wrap: wrap;
   gap: $square-cards-gap;

   .square-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: calc((100% - #{$square-cards-gap * 3}) / 4);
      aspect-ratio: 1;
      padding: 24px;
      background-color: $purple;
      color: $white;
      overflow: hidden;

      @media (max-width: 992px) {
         width: calc((100% - #{$square-cards-gap}) / 2);
         padding: 20px;
      }

      @media (max-width: 575px) {
         width: 100%;
         padding: 16px;
      }

      &__title {
         font-size: rem(56px);
         line-height: 1;

         display: -webkit-box;
         -webkit-box-orient: vertical;
         overflow: hidden;
         text-overflow: ellipsis;
         -webkit-line-clamp: 2;

         @media (min-width: 1440px) {
            font-size: rem(80px);
         }

         @media (min-width: 376px) and (max-width: 575px) {
            font-size: rem(72px);
         }

         @media (max-width: 375px) {
            font-size: rem(48px);
         }
      }

      &__content {
         font-size: rem(26px);
         line-height: 1;

         display: -webkit-box;
         -webkit-box-orient: vertical;
         overflow: hidden;
         text-overflow: ellipsis;
         -webkit-line-clamp: 3;

         @media (min-width: 1281px) {
            -webkit-line-clamp: 4;
         }

         @media (min-width: 992px) and (max-width: 1280px) {
            font-size: rem(24px);
         }

         @media (min-width: 769px) and (max-width: 992px) {
            font-size: rem(32px);
         }

         @media (max-width: 992px) {
            line-height: 1.2;
         }
      }

      &--purple {
         background-color: $purple;
         color: $white;
      }

      &--purple-50 {
         background-color: $purple-50;
         color: $white;
      }

      &--light-purple {
         background-color: $light-purple;
         color: $white;
      }

      &--light-purple-20 {
         background-color: $light-purple-20;
         color: $purple;
      }
   }
}