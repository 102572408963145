.news {
    &__content {
        align-items: center;

        .col-lg-6 {
            a {
                @media (max-width: 1024px) {
                    display: block;
                    margin-bottom: 24px;
                }
            }
        }
        
        .text-with-button {
            margin-bottom: 0px;

            .intro {
                h3 {
                    font-size: rem(32px);
                    line-height: rem(48px);
                    font-weight: 300;
                    margin-bottom: rem(32px);
                    
                    @media (max-width: 1024px) {
                        font-size: rem(24px);
                        line-height: rem(32px);
                        margin-bottom: rem(24px);
                    }
                }
    
                p {
                    font-size: rem(24px);
                    line-height: rem(36px);
                    font-weight: 300;
                    margin-bottom: rem(48px);
                    
                    @media (max-width: 1024px) {
                        font-size: rem(20px);
                        line-height: rem(28px);
                        margin-bottom: rem(20px);
                    }
                }
            }
        }
    }
}