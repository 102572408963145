.portfolio {
    &.bg-gradient-blue {
        background: rgb(255, 255, 255);
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(118, 120, 237, 0.24) 50%, rgba(255, 255, 255, 1) 100%);
    }

    &--desktop {
        @media (max-width: 1024px) {
            display: none;
        }
    }

    .intro {
        p {
            margin-bottom: 0px;
            font-size: rem(24px);
            line-height: rem(36px);
        }
    }

    &__column {
        &--left, &--right {
            .portfolio__item:not(:last-child) {
                margin-bottom: 182px;
            }
        }

        &--right {
            margin-top: 160px;
        }
    }

    &__item {
        .title {
            display: flex;
            align-items: baseline;
            font-size: rem(24px);
            line-height: rem(32px);
            margin-bottom: 24px;

            &::before {
                content: '';
                display: block;
                position: relative;
                top: -2px;
                width: 12px;
                height: 12px;
                background-color: $purple;
                border-radius: 50%;
                margin-right: 14px;
            }

            
        }

        figure {
            margin-bottom: 24px;
        }
        
        .description {
            font-size: rem(24px);
            line-height: rem(32px);
            letter-spacing: -1px;
            display: block;
        }
    }

    &--mobile {
        @media (min-width: 1024px) {
            display: none;
        }
        
        .portfolio__item {
            &:not(:last-child) {
                margin-bottom: 60px;
            }

            .title {
                font-size: rem(20px);
                margin-bottom: 8px;
            }

            figure {
                margin-bottom: 20px;
            }

            .description {
                font-size: rem(18px);
                line-height: rem(28px);
            }
        }
    }
}

.projects-page {    
    .intro {
        p {
            margin-bottom: 0px;
        }
    }
}

// .projects-main {
//     .portfolio--desktop {
//         margin-bottom: 150px;
//     }
//     .portfolio__column {
//         &--right {
//             margin-top: 104px;
//         }
//         &--left, &--right {
//             .portfolio__item {
//                 &:not(:last-child) {
//                     margin-bottom: 182px;
//                 }
//             }
//         }
//     }
// }