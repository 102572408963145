@font-face {
	font-family: 'Px Grotesk Light';
	src:
		url('../fonts/Px-Grotesk-Light.ttf') format('ttf'),
		url('../fonts/Px-Grotesk-Light.woff2') format('woff2'),
		url('../fonts/Px-Grotesk-Light.woff') format('woff'),
		url("../fonts/Px-Grotesk-Light.eot"),
		url("../fonts/Px-Grotesk-Light.eot?#iefix") format("embedded-opentype");
	//font-weight: 500;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Px Grotesk Regular';
	src:
		url('../fonts/Px-Grotesk-Regular.ttf') format('ttf'),
		url('../fonts/Px-Grotesk-Regular.woff2') format('woff2'),
		url('../fonts/Px-Grotesk-Regular.woff') format('woff'),
		url("../fonts/Px-Grotesk-Regular.eot"),
		url("../fonts/Px-Grotesk-Regular.eot?#iefix") format("embedded-opentype");
	//font-weight: 500;
	font-style: normal;
	font-stretch: normal;
}

.typography-h6 {
    font-size: rem(21px);
    line-height: rem(28px);

    @media (max-width: 1024px) {
        font-size: rem(16px);
        line-height: rem(18px);
    }
}