.small-box {
    .row {
        @media (min-width: 1025px) {
            margin-inline: 32px !important;
        }

        @media (max-width: 1024px) {
            background-color: #EDEEFF;
        }
    }
    
    &__content {
        background-color: #EDEEFF;
        padding: 56px 62px;

        @media (max-width: 1024px) {
            padding: 52px calc(22px + 16px);
        }

        @media (max-width: 768px) {
            margin-inline: auto;
            max-width: calc(540px + 2 * 20px);
        }
        
        @media (max-width: 575px) {
            max-width: calc(390px + 2 * 20px);
        }

        p {
            font-size: rem(20px);
            line-height: rem(32px);
            text-align: justify;
            margin-bottom: 0px;
            
            &:not(:last-child) {
                margin-bottom: 32px;
            }

            @media (max-width: 1024px) {
                font-size: rem(17px);
                line-height: rem(26px);
                
                &:not(:last-child) {
                    margin-bottom: 26px;
                }
            }
        }
    }
}