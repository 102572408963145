.page-title {
   display: flex;
   flex-direction: column;
   align-items: center;

   &__icon {
      font-size: 40px;
      line-height: 40px;
      margin-bottom: 12px;
   }

   h1 {
      text-align: center;
      margin-bottom: 42px;
      font-size: rem(80px);
      line-height: rem(90px);
      letter-spacing: -2.5%;
      color: #1F1F1F;
      width: 100%;

      @media(max-width:1024px) {
         font-size: rem(32px);
         line-height: rem(44px);
         letter-spacing: -1px;
         margin-bottom: 20px;
      }

      span {
         font-size: rem(40px);
         float: right;

         @media(max-width:1024px) {
            display: none;
         }
      }

   }

   .intro {
      width: 100%;

      p {
         font-size: rem(32px);
         line-height: rem(44px);
         letter-spacing: -1px;
         text-align: center;

         @media(max-width:1024px) {
            font-size: rem(20px);
            line-height: rem(32px);
            letter-spacing: -1px;
            margin-bottom: 0px;
         }

      }
   }

   &.page-title-left {

      h1,
      .intro p {
         text-align: left;
      }
   }
}

.page-title {
   &--articles {
      margin-block: 0 100px;

      @media (max-width: 1024px) {
         margin-block: 0 52px;
      }

      h1 {
         margin-bottom: 0;
      }
   }

   &--contact {
      h1 {
         margin-bottom: 42px;

         @media (max-width: 1024px) {
            margin-bottom: 20px;
         }
      }

      .intro {
         p {
            margin-bottom: 0px;
         }
      }
   }

   &--article {
      margin-bottom: 88px;

      @media (max-width: 1024px) {
         margin-bottom: 40px;
      }

      .intro {
         p {
            margin-bottom: 0;
         }
      }
   }

   &--services {
      margin-bottom: 150px;

      @media (max-width: 1024px) {
         margin-bottom: 45px;
      }

      .intro {
         p {
            margin-bottom: 0;
         }
      }
   }

   &--projects {
      margin-bottom: 148px;

      @media (max-width: 1024px) {
         margin-bottom: 60px;
      }

      .intro {
         margin-bottom: 0;
      }
   }
}