.block--partners-block {
   --gap-inline: 15px;

   @media (min-width: 575px) {
      --gap-inline: 28px;
   }
 
   h1 {
      letter-spacing: -1px;
      line-height: 1.3;
      font-size: calc(1.375rem + 1.5vw);
      margin-bottom: 30px;

      @media (min-width: 575px) {
         font-size: rem(48px);
         margin-bottom: rem(52px);
      }
   }

   .partners-block-group {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: var(--gap-inline);

      @media (min-width: 575px) {
         grid-template-columns: repeat(3, 1fr);
      }

      @media (min-width: 1024px) {
         grid-template-columns: repeat(4, 1fr);
      }

      @media (min-width: 1440px) {
         grid-template-columns: repeat(5, 1fr);
      }

      .partner-block {
         position: relative;
         width: 100%;
         height: 0;
         padding-bottom: 100%;
         display: block;
         border: 1px solid transparent;

         &.hasBorder {
            border: 1px solid #7678ed;
         }

         &.lightBorder {
            border: 1px solid #eee;
         }

         &[href] {
            transition: border-color .3s ease-in-out;

            &:hover {
               border-color: #eee;

               &.hasBorder {
                  border-color: #632D92;
               }

               &.lightBorder {
                  border-color: #7678ed;
               }

               img {
                  opacity: .75;
               }
            }

            img {
               transition: opacity .3s ease-in-out;
            }
         }

         img,
         svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: calc(100% - (var(--gap-inline) * 2));
            height: auto;
            max-height: calc(100% - (var(--gap-inline) * 2));
            object-fit: contain;
         }
      }
   }
}