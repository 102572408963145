.main-banner {
    position: relative;
    z-index: 1;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    padding-bottom: 56px;
    display: flex;
    align-items: flex-end;

    @media(max-width: 1024px) {
        padding-bottom: 20px;
    }

    &__container {
        position: relative;
        height: 100%;
        top: auto;
        left: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        width: 80%;

        h1 {
            font-size: rem(80px);
            line-height: rem(94px);
            letter-spacing: -1px;
            color: #FFFFFF;
            margin-bottom: 0;
    
            @media(max-width: 1024px) {
                font-size: rem(40px);
                line-height: rem(48px);
            }
        }

        @media (max-width: 575px) {
            width: 91%;
        }
    }
    
    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: end;
        flex-shrink: 0;
        width: 100px;

        img {
            display: block;
            height: 65px;
            
            @media (max-width: 1024px) {
                height: 40px;
            }
        }

        @media (min-width: 1025px) {
            margin-bottom: 8px;
        }

        @media (max-width: 575px) {
            width: 9%;
        }
    }
}