.fading-in {
//     overflow: hidden;

    .row {
        transform: none;
        opacity: 1;
        visibility: visible;
        transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;
    }

    &--faded {
        .row {
            visibility: hidden;
            transform: translateY(30vh);
            opacity: 0;

            @media (max-width: 768px) {
                transform: translateY(8vh);
            }
        }
    }
}