
.image{
   display: block;

	img{
		max-width: 100%;
		width: 100%;
		height: auto;

		@media(max-width:1024px){
            max-width: inherit;
            height: 100%;
            width: auto;
      }
	}
}

.container.about-us {
   .image {
      margin-top: 80px;

      @media (max-width: 1024px) {
         margin-top: 60px;
      }
   }
}