.vacancy-main {
   .banner {
      align-items: center;
      position: relative;

      &__content {
         color: #fff;

         .job-info {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            font-size: rem(19px);
            line-height: 1;
            margin-bottom: 8px;
            gap: 8px;

            .spacer {
               font-size: 10px;
               line-height: 18px;
            }

            * {
               margin-bottom: 0;
            }

            @media (max-width: 1024px) {
               font-size: rem(14px);

               .spacer {
                  font-size: 8px;
                  line-height: 13px;
               }
            }
         }

         .title {
            margin-bottom: 32px;
            text-align: center;

            font-size: rem(56px);
            line-height: 1;

            @media (max-width: 1024px) {
               font-size: rem(32px);
               line-height: 1;
            }
         }

         .description {
            font-size: rem(28px);
            line-height: rem(34px);
            text-align: center;

            @media (max-width: 1024px) {
               font-size: rem(17px);
               line-height: rem(26px);
            }

            margin-bottom: 0px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;

            @media (min-width: 769px) {
               -webkit-line-clamp: 4;
            }

            @media (max-width: 768px) {
               -webkit-line-clamp: 6;
            }

            @media (max-height: 500px) {
               -webkit-line-clamp: 2;
            }
         }
      }
   }
}

.vacancy {
   &__content {
      @extend .article;
      margin-bottom: 80px;

      @media (max-width: 1024px) {
         margin-bottom: 60px;
      }
   }

   &__footer {
      margin-bottom: 150px;
      font-size: rem(19px);
      line-height: 1;
      display: flex;
      justify-content: space-between;

      @media (max-width: 576px) {
         flex-direction: column;
         gap: 12px;
      }

      .responsible {
         display: flex;
         gap: 8px;

         .spacer {
            font-size: 10px;
            line-height: 18px;
         }

         a {
            text-decoration: underline;
            text-underline-offset: 3px;
            color: inherit;

            &:hover {
               color: $purple;
            }
         }
      }

      @media (max-width: 1024px) {
         margin-bottom: 100px;
         font-size: rem(16px);
      }
   }

   &__form {
      @extend .form !optional;

      .form {
         &__external-btn {
            @extend .form__submit;

            button {
               margin-top: 0;
               padding-block: 18px;
               padding-inline: 32px 28px;
               font-size: 1.5rem;
               line-height: 1;
               
               @media (max-width: 1024px) {
                  padding-block: 14px;
                  padding-inline: 28px 14px;
                  font-size: 1.15rem;
               } 

               span {
                  display: flex;
                  align-items: center;
                  gap: 6px;
                  
                  &::after {
                     content: '';
                     display: inline-block;
                     position: relative;
                     width: 1.65rem;
                     aspect-ratio: 1;
                     top: 0;
                     left: 0;
                     background-image: url("./../images/arrow-up-right.svg");
                     background-size: 1.65rem;
                     background-position: center;
                     background-repeat: no-repeat;
                     filter: invert(1);
                     rotate: -135deg;
                     transition-property: top, left;
                     transition: 220ms ease-in-out;

                     @media (max-width: 1024px) {
                        width: 1.25rem;
                        background-size: 1.25rem;
                     }
                  }
               }

               &:hover {
                  border-color: $purple !important;
                  span {
                     &::after {
                        filter: initial;
                        top: -7px;
                        left: 7px;
                     }
                  }
               }
            }
         }
         
         &__intro {
            .title {
               margin-bottom: 32px;
               text-align: center;

               font-size: rem(56px);
               line-height: 1;

               @media (max-width: 1024px) {
                  font-size: rem(32px);
                  line-height: 1;
               }
            }

            .description {
               font-size: rem(28px);
               line-height: rem(34px);
               text-align: center;

               @media (max-width: 1024px) {
                  font-size: rem(17px);
                  line-height: rem(26px);
               }

               margin-bottom: 0px;
               display: -webkit-box;
               -webkit-box-orient: vertical;
               overflow: hidden;
               text-overflow: ellipsis;

               @media (min-width: 769px) {
                  -webkit-line-clamp: 4;
               }

               @media (max-width: 768px) {
                  -webkit-line-clamp: 6;
               }

               @media (max-height: 500px) {
                  -webkit-line-clamp: 2;
               }
            }

         }

         &__content {
            .info-upload {
               label {
                  width: 100%;
                  padding: 10px;
                  border: 1px solid #632D9244;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;

                  &:not(:last-child) {
                     margin-bottom: 16px;
                  }

                  &:focus {
                     outline: none;
                     border-bottom: 1px solid #632D92;
                  }

                  input {
                     position: absolute;
                     padding: 0;
                     top: 0;
                     left: 0;
                     width: 1px;
                     height: 1px;
                     border: none;
                     overflow: hidden;
                  }

                  &.filled {
                     .label-text {
                        &::before {
                           background-image: url('./../images/file.svg');
                           background-size: 24px;
                        }
                     }
                  }

                  .label-text {
                     padding: 10px;
                     outline: 3px dashed #7678ed44;
                     display: flex;
                     align-items: center;
                     justify-content: center;
                     width: 100%;
                     gap: 12px;

                     span {
                        line-height: 16px;
                        margin-bottom: 0;
                     }

                     &::before {
                        content: '';
                        display: block;
                        position: relative;
                        width: 20px;
                        aspect-ratio: 1;
                        background-image: url('./../images/upload.svg');
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                     }
                  }
               }
            }
         }
      }
   }
}