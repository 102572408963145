.image-block {
   img {
      width: 100%;
      height: auto;
   }

   figcaption {
      display: inline-block;
      position: relative;
      text-decoration: none !important;
      color: #1f1f1f;
      font-size: rem(15px);
      line-height: rem(20px);
      margin-top: 12px;
   }
}