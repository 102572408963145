.about-page{
  h1{
    margin-bottom: 50px;
    font-size: rem(80px);
    line-height: rem(90px);
    letter-spacing: -2.5%;

    @media(max-width: 1024px){
      font-size: rem(32px);
      line-height: rem(44px);
      letter-spacing: -1px;
      margin-bottom: 20px;
    }
  }

  figure.full{
    @media(max-width: 1024px){
      margin-right: -16px;
      margin-left: -16px;
      width: calc(100% + 32px);
    }
  }

  .intro-text{
    margin: 150px 0px;
    
    @media(max-width: 1024px){
      margin: 40px 0 60px 0;
    }

    p{
      font-size: rem(32px);
      line-height: rem(44px);
      letter-spacing: -1px;
      @media(max-width: 1024px){
        font-size: rem(20px);
        line-height: rem(32px);
      }
    }
  }
}

.customSpacer {
   margin-bottom: 150px;

   @media (max-width: 992px) {
      margin-bottom: 60px;
   }
}