.customers{
	//padding: 93px 0;
	//overflow: hidden;
/*
	@media(max-width: 1024px){
		//padding: 80px 0;
		margin-bottom: 80px;
	}

	&__wrapper{
		padding: 40px 0;
		border-top: 1px solid $gray-300;
		border-bottom: 1px solid $gray-300;
		display: -webkit-inline-box;
		//overflow: auto;
		max-width: 100%;

		@media(max-width: 1024px){
			padding: 24px 0;
		}

		&--item{
			//display: inline-block;
			margin: 0 20px;
			img{
				height: 78px;
				width: auto;

				@media(max-width: 1024px){
					height: 45px;
				}

			}
		}
	}

  */
}


.slick-initialized{
  .slick-slide{
    display: flex;
    justify-content: center;
  }
}

    
    
    
      