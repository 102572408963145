.articles{
  @media (max-width: 1024px) {
    margin-bottom: 60px;
  }

  .article-item{
    &:not(:last-child) {
        margin-bottom: 100px;

        @media (max-width: 1024px) {
            margin-bottom: 46px;
        }
    }

    figure{
      margin-bottom: 24px;
      
      @media (max-width: 1024px) {
        margin-bottom: 12px;
      }
    }

    h2{
      font-size: rem(24px);
      line-height: rem(32px);
      letter-spacing: -1px;
      margin-bottom: 14px;
      
      @media (max-width: 1024px) {
          font-size: rem(18px);
          line-height: rem(28px);
          margin-bottom: 8px;
      }
    }
    
    .intro{
        p{
            font-size: rem(21px);
        line-height: rem(28px);
        letter-spacing: -0.5px;
        font-family: "Px Grotesk Light", sans-serif;
        margin-bottom: 0px;

        @media (max-width: 1024px) {
            font-size: rem(17px);
            line-height: rem(26px);
            color: #616161;
        }
      }
    }
  }
}

